import type {BroadcastFn} from '@backstage-components/base';
import {assign, createMachine} from 'xstate';
import {
  type InstructionSchema,
  PublicAccessCodeInstructionSchema,
} from './PublicAccessCodeDefinition';

/**
 * @private exported for tests
 */
export interface ViewContext {
  broadcast: BroadcastFn<typeof PublicAccessCodeInstructionSchema>;
  /**
   * The error message if there is one. Allows `undefined` or `null` as empty
   * value because XState's `assign` treats `undefined` like "no update".
   */
  error?: string | null;
}

type Typestate =
  | {value: 'login'; context: ViewContext}
  | {value: 'loginPending'; context: ViewContext}
  | {value: 'success'; context: ViewContext};

export const PublicAccessCodeViewMachine = createMachine<
  ViewContext,
  InstructionSchema,
  Typestate
>(
  {
    id: 'PublicAccessCodeResend',
    initial: 'login',
    states: {
      login: {
        on: {
          'PublicAccessCode:verify': {
            target: 'loginPending',
            actions: ['clearError'],
          },
        },
      },
      loginPending: {
        on: {
          'PublicAccessCode:on-failure': {
            target: 'login',
            actions: [assign({error: (context, event) => event.meta.reason})],
          },
          'PublicAccessCode:on-success': {target: 'success'},
        },
      },
      success: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      clearError: assign<ViewContext, InstructionSchema>({
        error: () => null,
      }),
    },
  }
);
